@charset "UTF-8";

@font-face {
	font-family: 'Manrope';
	font-display: swap;
	src: url('manrope/manrope-extrabold.woff2') format('woff2'),
		/* Modern browsers */ url('manrope/manrope-extrabold.woff') format('woff'),
		/* Legacy browsers */ url('manrope/manrope-extrabold.otf') format('truetype'); /* Android, iOS */
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: 'Manrope';
	font-display: swap;
	src: url('manrope/manrope-bold.woff2') format('woff2'),
		url('manrope/manrope-bold.woff') format('woff'),
		url('manrope/manrope-bold.otf') format('truetype');
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'Manrope';
	font-display: swap;
	src: url('manrope/manrope-semibold.woff2') format('woff2'),
		url('manrope/manrope-semibold.woff') format('woff'),
		url('manrope/manrope-semibold.otf') format('truetype');
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'Manrope';
	font-display: swap;
	src: url('manrope/manrope-medium.woff2') format('woff2'),
		url('manrope/manrope-medium.woff') format('woff'),
		url('manrope/manrope-medium.otf') format('truetype');
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'Manrope';
	font-display: swap;
	src: url('manrope/manrope-regular.woff2') format('woff2'),
		url('manrope/manrope-regular.woff') format('woff'),
		url('manrope/manrope-regular.otf') format('truetype');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Manrope';
	font-display: swap;
	src: url('manrope/manrope-light.woff2') format('woff2'),
		url('manrope/manrope-light.woff') format('woff'),
		url('manrope/manrope-light.otf') format('truetype');
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'Manrope';
	font-display: swap;
	src: url('manrope/manrope-thin.woff2') format('woff2'),
		url('manrope/manrope-thin.woff') format('woff'),
		url('manrope/manrope-thin.otf') format('truetype');
	font-style: normal;
	font-weight: 200;
}
